import axios from 'axios'
import config from '../config/dev.json'
import production from '../config/production.json'

const path = '/api/contact'
let url

if (process.env.NODE_ENV === 'production') url = production.host + path
else url = config.host + path

url = 'https://elicia2021.herokuapp.com/api/command'


export function GetAllCommandes() {
  return new Promise((resolve, reject) => {
    axios.get(url).then(response => {
      resolve(response.data)
    }).catch(err => resolve("echeck"))
  })
}

export function AddCommandeService(commande) {
    return new Promise((resolve, reject) => {
        axios.post(url, commande).then(response => {
            resolve(response)
        }).catch(err => resolve("echeck"))
    }) 
}

export function UpdateCommandeService(commande) {
    return new Promise((resolve, reject) => {
        axios.put(url, commande).then(response => {
            resolve(response.data)
        }).catch(err => reject(err))
    }) 
}

export function DeleteCommandeService(commande) {
    return new Promise((resolve, reject) => {
        axios.delete(url + '/'+ commande._id).then(response => {
            resolve(response)
        }).catch(err => reject(err))
    }) 
}


