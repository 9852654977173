import { GetAllUsers, AddUsersService, UpdateUsersService, DeleteUsersService } from '@/api/users.service'

export default {

    state: {
        users: []
    },

    getters: {
        getCurrentUsers(state) {
            return state.users
        },
    },

    mutations: {

        setCurrentUsers(state, users) {
            state.users = users
        },
        setCurrentAddUsers(state, users) {
            state.users.push(users)
        },
        setCurrentUpdateUsers(state, users) {
            let newt = state.users
            for (var i = 0; i < newt.length; i++) {
                if (newt[i]._id == users._id) {
                    newt[i] = users
                    state.users = []
                    state.users = newt
                }
            }
        },
        setCurrentDeleteUsers(state, usersId) {
            for (var i = 0; i < state.users.length; i++) {
                if (state.users[i]._id == usersId) {
                    state.users.splice(i, 1);
                    break
                }
            }
        },

    },

    actions: {
        async ListUsers(context) {
            let response = await GetAllUsers()
            console.log("eeeeea", response)
            if (response !== "echeck")
                context.commit('setCurrentUsers', response.data)
            return response
        },
        async AddUsers(context, item) {
            let response = await AddUsersService(item)
            if (response.data.data._id !== undefined) {
                context.commit('setCurrentAddUsers', response.data.data)
            }
            return response
        },
        async UpdateUsers(context, item) {
            let response = await UpdateUsersService(item)
            if (response._id !== undefined) {
                context.commit('setCurrentUpdateUsers', response)
                return response
            } else {
                return response
            }
        },
        async DeleteUsers(context, item) {
            let response = await DeleteUsersService(item)
            if (response.data !== '')
                context.commit('setCurrentDeleteUsers', item._id)
            return response
        },

    }
}