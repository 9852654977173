import axios from 'axios'
import config from '../config/dev.json'
import production from '../config/production.json'

const path = '/api/contact'
let url

if (process.env.NODE_ENV === 'production') url = production.host + path
else url = config.host + path

url = 'https://elicia2021.herokuapp.com/api/contacts'


export function GetAllContact() {
  return new Promise((resolve, reject) => {
    axios.get(url).then(response => {
      resolve(response.data)
    }).catch(err => resolve("echeck"))
  })
}


export function DeleteContactService(contact) {
  return new Promise((resolve, reject) => {
      axios.delete(url + '/'+ contact._id).then(response => {
          resolve(response)
      }).catch(err => reject(err))
  }) 
}

