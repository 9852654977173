import axios from 'axios'
import config from '../config/dev.json'
import production from '../config/production.json'

const path = '/api'
let url

if (process.env.NODE_ENV === 'production') url = production.host + path
else url = config.host + path

url = 'http://102.219.178.151/backend/api/'

export function LoginService(data) {
  return new Promise((resolve, reject) => {
    axios.post(url+'api/login', data).then(response => {
      resolve(response.data)
    }).catch(err => resolve("echeck"))
  })
}

export function updateInfoService(user) {
  return new Promise((resolve, reject) => {
    axios.put('http://102.219.178.151/backend/api/users/'+user._id,user).then(response => {
      resolve(response)
    }).catch(err => resolve("err"))
  })
}

export function updatePasswordService(data) {
  let id = data._id

  delete data._id
  return new Promise((resolve, reject) => {
    axios.put('http://102.219.178.151/backend/api/users/'+id+'/new-password',data).then(response => {
      resolve(response)
    }).catch(err => resolve("err"))
  })
}

export function logoutService(user) {
  return new Promise((resolve, reject) => {
    axios.post(`${url}/logout/`, user).then(response => {
      localStorage.removeItem('token')
      resolve(response)
    }).catch(err => reject(err))
  })
}

export async function getInfo(token) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.Authorization = `Bearer ${token}`
    axios.get(`${url}/view-user/`).then(response => {
      resolve(response.data.response)
    }).catch(err => reject(err))
  })
}

export async function refresh() {
  return new Promise((resolve, reject) => {
    if (localStorage.token) {
      const { token } = localStorage
      setToken(token)
      axios.get(`${url}/refresh-token/`).then(data => {
        if (data.response !== 'Unauthenticated!') setToken(data.token)
        resolve(data.data.token)
      }).catch(err => reject(err))
    } else {
      reject('logout and login again')
    }
  })
}

export async function setToken(token) {
  axios.defaults.headers.Authorization = `Bearer ${token}`
}
