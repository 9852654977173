import { GetAllPostBoosts, AddPostBoostService, UpdatePostBoostService, DeletePostBoostService  } from '@/api/postboost.service'

export default {

    state: {
        posts : []
    },

    getters: {
        getCurrentPostBoosts(state) {
            return state.posts
        },
    },

    mutations: {

        setCurrentPostBoosts(state, posts) {
            state.posts = posts
        },
        setCurrentAddPostBoosts(state, post) {
            state.posts.push(post)
        },
        setCurrentUpdatePostBoosts(state, post) {
            let newt = state.posts
            for (var i = 0; i < newt.length; i++) {
                if (newt[i]._id == post._id) {
                    newt[i] = post
                    state.posts = []
                    state.posts = newt 
                }
            }
        },
        setCurrentDeletePostBoosts(state, postId) {
            for (var i = 0; i<state.posts.length; i++) {
                if (state.posts[i]._id == postId) {
                    state.posts.splice(i, 1);
                    break
                }
            }
        },

    },

    actions: {
        async ListPostBoosts(context) {
            let new_contacts = await GetAllPostBoosts()
            if(new_contacts!=="echeck")
                context.commit('setCurrentPostBoosts', new_contacts.data)

            return new_contacts
        },
        async AddPostBoost(context, item) {
            let response = await AddPostBoostService(item)
            if(response.data.data._id!==undefined) {
                
                context.commit('setCurrentAddPostBoost', response.data.data)
            }
            return response
        },
        async UpdatePostBoost(context, item) {
            let response = await UpdatePostBoostService(item)
            if (response._id !== undefined) {
                context.commit('setCurrentUpdatePostBoost', response)
                return response
            } else {
                return response
            }
        },
        async DeletePostBoost(context, item) {
            let response = await DeletePostBoostService(item)
            if(response.data!=='') 
                context.commit('setCurrentDeletePostBoost', item._id)
            return response
        },

    }
}