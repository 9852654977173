import { GetAllProductBoosts, AddProductBoostService, UpdateProductBoostService, DeleteProductBoostService  } from '@/api/boostproduct.service'

export default {

    state: {
        productBoosts : []
    },

    getters: {
        getCurrentProductBoosts(state) {
            return state.productBoosts
        },
    },

    mutations: {

        setCurrentProductBoosts(state, productBoosts) {
            state.productBoosts = productBoosts
        },
        setCurrentAddProductBoosts(state, productBoost) {
            state.productBoosts.push(productBoost)
        },
        setCurrentUpdateProductBoosts(state, productBoost) {
            let newt = state.productBoosts
            for (var i = 0; i < newt.length; i++) {
                if (newt[i]._id == productBoost._id) {
                    newt[i] = productBoost
                    state.productBoosts = []
                    state.productBoosts = newt 
                }
            }
        },
        setCurrentDeleteProductBoosts(state, productBoostId) {
            for (var i = 0; i<state.productBoosts.length; i++) {
                if (state.productBoosts[i]._id == productBoostId) {
                    state.productBoosts.splice(i, 1);
                    break
                }
            }
        },

    },

    actions: {
        async ListProductBoosts(context) {
            let new_contacts = await GetAllProductBoosts()
            if(new_contacts!=="echeck")
                context.commit('setCurrentProductBoosts', new_contacts.data)

            return new_contacts
        },
        async AddProductBoost(context, item) {
            let response = await AddProductBoostService(item)
            if(response.data.data._id!==undefined) {
                
                context.commit('setCurrentAddProductBoost', response.data.data)
            }
            return response
        },
        async UpdateProductBoost(context, item) {
            let response = await UpdateProductBoostService(item)
            if (response._id !== undefined) {
                context.commit('setCurrentUpdateProductBoost', response)
                return response
            } else {
                return response
            }
        },
        async DeleteProductBoost(context, item) {
            let response = await DeleteProductBoostService(item)
            if(response.data!=='') 
                context.commit('setCurrentDeleteProductBoost', item._id)
            return response
        },

    }
}