import axios from 'axios'
import config from '../config/dev.json'
import production from '../config/production.json'

const path = '/api/contact'
let url

if (process.env.NODE_ENV === 'production') url = production.host + path
else url = config.host + path

url = 'http://102.219.178.151/backend/api/users/subscription'

export function GetAllSubscription() {
  return new Promise((resolve, reject) => {
    axios.get(url).then(response => {
      resolve(response.data)
    }).catch(err => resolve("echeck"))
  })
}
