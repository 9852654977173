import {
  LoginService, refresh, getInfo, logoutService, updateInfoService,
} from '@/api/auth.service.js'

export default {
  state: {
    user: {},
    auth: false,
  },
  getters: {
    getCurrentUser(state) {
      return state.user
    },
    getCurrentAuth(state) {
      return state.auth
    },
  },
  mutations: {
    setCurrentUser(state, user) {
      state.user = user
    },
    setCurrentAuth(state, status) {
      state.auth = status
    },
  },
  actions: {
    async login(context, data) {
      const new_data = await LoginService(data)
      if (new_data!== 'echeck') {
        localStorage.token = new_data.token
        localStorage.user = JSON.stringify(new_data.user)
        context.commit('setCurrentUser', new_data.user)
        context.commit('setCurrentAuth', true)
      }
      return new_data
    },

      logout(context) {
      context.commit('setCurrentUser', {})
      context.commit('setCurrentAuth', false)
      context.commit('setCurrentUsers', [])
      context.commit('setCurrentProducts', [])
      return 1
    },

    async updateInfo(context, user) {
      const data = await updateInfoService(user)
      context.commit('setCurrentUser', data.data)
      localStorage.user = JSON.stringify(data.data)
      return data
    },

    async refresh(context) {
      const token = await refresh()
      const data = await getInfo(token)
      if (data !== 'Unauthenticated!') {
        context.commit('setCurrentUser', data)
        context.commit('setCurrentAuth', true)
      }
      return data
    },
  },
}
