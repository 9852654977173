import { GetAllCategories, AddSubCategoryService, UpdateSubCategoryService, DeleteSubCategoryService  } from '@/api/subcategory.service'

export default {

    state: {
        subcategories : []
    },

    getters: {
        getCurrentSubCategories(state) {
            return state.subcategories
        },
    },

    mutations: {

        setCurrentSubCategories(state, subcategories) {
            state.subcategories = subcategories
        },
        setCurrentAddSubCategory(state, subcategory) {
            state.subcategories.push(subcategory)
        },
        /*setCurrentUpdateCategory(state, category) {
            let newt = state.categories
            for (var i = 0; i < newt.length; i++) {
                if (newt[i]._id == category._id) {
                    newt[i] = category
                    state.categories = []
                    state.categories = newt 
                }
            }
        },
        setCurrentDeleteCategory(state, categoryId) {
            for (var i = 0; i<state.categories.length; i++) {
                if (state.categories[i]._id == categoryId) {
                    state.categories.splice(i, 1);
                    break
                }
            }
        },*/

    },

    actions: {
        async ListSubCategories(context) {
            let new_categories = await GetAllCategories()
            let new_subcategories = []
            if (new_categories !== "echeck") {
                for (let i = 0; i < new_categories.data.length; i++) {
                    console.log("haha ",new_categories.data[i])
                    for (let j = 0; j < new_categories.data[i].subCategories.length; j++) {
                        new_subcategories.push(new_categories.data[i].subCategories[j])
                    }
                }
                context.commit('setCurrentSubCategories', new_subcategories)
            }

            return new_subcategories
        },
        async AddSubCategory(context, item) {
            let response = await AddSubCategoryService(item)
            if(response.data._id!==undefined) {
            let new_categories = await GetAllCategories()
            let new_subcategories = []
            if (new_categories !== "echeck") {
                for (let i = 0; i < new_categories.data.length; i++) {
                    console.log("haha ",new_categories.data[i])
                    for (let j = 0; j < new_categories.data[i].subCategories.length; j++) {
                        new_subcategories.push(new_categories.data[i].subCategories[j])
                    }
                }
                context.commit('setCurrentSubCategories', new_subcategories)
            }
            return new_subcategories
            }
        },
        async UpdateSubCategory(context, item) {
            let response = await UpdateSubCategoryService(item)
            console.log("after update ",response)
            let new_categories = await GetAllCategories()
            let new_subcategories = []
            if (new_categories !== "echeck") {
                for (let i = 0; i < new_categories.data.length; i++) {
                    for (let j = 0; j < new_categories.data[i].subCategories.length; j++) {
                        new_subcategories.push(new_categories.data[i].subCategories[j])
                    }
                }
                context.commit('setCurrentSubCategories', new_subcategories)
            }
            return new_subcategories
           
        },
        async DeleteSubCategory(context, item) {
            let response = await DeleteSubCategoryService(item)
            let new_categories = await GetAllCategories()
            let new_subcategories = []
            if (new_categories !== "echeck") {
                for (let i = 0; i < new_categories.data.length; i++) {
                    for (let j = 0; j < new_categories.data[i].subCategories.length; j++) {
                        new_subcategories.push(new_categories.data[i].subCategories[j])
                    }
                }
                context.commit('setCurrentSubCategories', new_subcategories)
            }
            return new_subcategories
        },

    }
}