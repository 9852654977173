import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/dashboard/ecommerce',
      name: 'dashboard-ecommerce',
      component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Statistique',
            active: true,
          },
        ],
      },
    },
    {
      path: '/products/list',
      name: 'apps-e-commerce-shop',
      component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
      meta: {
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: 'Products',
        breadcrumb: [
          {
            text: 'Liste des produits',
          },
        ],
      },
    },
    {
      path: '/products/details/list/:id',
      name: 'apps-e-commerce-product-details',
      component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
      props: true,
      meta: {
        pageTitle: 'Product Details',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'Liste des produits',
          },
          {
            text: 'Détails produit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/products/list/edit/:id',
      name: 'apps-e-commerce-product-edit',
      component: () => import('@/views/apps/e-commerce/e-commerce-product-details/EditProduct.vue'),
      props: true,
      meta: {
        pageTitle: 'Product Edit',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'Liste des produits',
          },
          {
            text: 'Détails produit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/products/list/add',
      name: 'apps-e-commerce-product-add',
      component: () => import('@/views/apps/e-commerce/e-commerce-product-details/addProduct.vue'),
      props: true,
      meta: {
        pageTitle: 'Product Add',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'Liste des produits',
          },
          {
            text: 'Ajouter un produit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/shop/categories',
      name: 'apps-e-commerce-shop-categories',
      component: () => import('@/views/apps/category/category.vue'),
      meta: {
        pageTitle: 'Products',
        breadcrumb: [
          {
            text: 'Liste des catégories',
          },
        ],
      },
    },
    {
      path: '/shop/sub-categories',
      name: 'apps-e-commerce-shop-sub-categories',
      component: () => import('@/views/apps/category/subcategory.vue'),
      meta: {
        pageTitle: 'Products',
        breadcrumb: [
          {
            text: 'Liste des sous catégories',
          },
        ],
      },
    },
    {
      path: '/shop/tags',
      name: 'apps-e-commerce-shop-tags',
      component: () => import('@/views/apps/category/tags.vue'),
      meta: {
        pageTitle: 'Products',
        breadcrumb: [
          {
            text: 'Liste des tags',
          },
        ],
      },
    },
    // {
    //   path: '/apps/e-commerce/wishlist',
    //   name: 'apps-e-commerce-wishlist',
    //   component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
    //   meta: {
    //     pageTitle: 'Wishlist',
    //     contentClass: 'ecommerce-application',
    //     breadcrumb: [
    //       {
    //         text: 'ECommerce',
    //       },
    //       {
    //         text: 'Wishlist',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/apps/e-commerce/checkout',
    //   name: 'apps-e-commerce-checkout',
    //   component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
    //   meta: {
    //     pageTitle: 'Checkout',
    //     contentClass: 'ecommerce-application',
    //     breadcrumb: [
    //       {
    //         text: 'ECommerce',
    //       },
    //       {
    //         text: 'Checkout',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/pages/notification',
      name: 'pages-notification',
      component: () => import('@/views/apps/notification/notification.vue'),
      meta: {
        pageTitle: 'Notification',
        breadcrumb: [
          {
            text: 'Notification',
          },
        ],
      },
    },
     {
      path: '/pages/account-setting',
      name: 'pages-account-setting',
      component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
      meta: {
        pageTitle: 'Paramètre',
        breadcrumb: [
          {
            text: 'Paramètre du compte',
          },
        ],
      },
    },
    {
      path: '/commandes/',
      name: 'commandes',
      component: () => import('@/views/apps/commande/listCommande.vue'),
      meta: {
        pageTitle: 'Commandes',
        breadcrumb: [
          {
            text: 'Commande',
            active: true,
          },
        ],
      },
    },
  // {
  //   path: '/pages/profile',
  //   name: 'pages-profile',
  //   component: () => import('@/views/pages/profile/Profile.vue'),
  //   meta: {
  //     pageTitle: 'Profile',
  //     breadcrumb: [
  //       {
  //         text: 'Pages',
  //       },
  //       {
  //         text: 'Profile',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
    {
      path: '/users/list/particular',
      name: 'apps-users-list',
      props: true,
      component: () => import('@/views/apps/user/users-list/UsersList.vue'),
      meta: {
        pageTitle: 'Utilisateurs',
        breadcrumb: [
          {
            text: 'Liste des utilisateurs',
            active: true,
          },
        ],
      }
    },
    {
      path: '/users/list/demand',
      name: 'apps-pro-demand',
      props: true,
      component: () => import('@/views/apps/pro-demand/pro-demand.vue'),
      meta: {
        pageTitle: 'Demande pro',
        breadcrumb: [
          {
            text: 'Liste des Demande',
            active: true,
          },
        ],
      }
    },
    {
      path: '/users/list/professional',
      name: 'apps-pro-list',
      props: true,
      component: () => import('@/views/apps/user/users-list/ProList.vue'),
      meta: {
        pageTitle: 'Utilisateurs',
        breadcrumb: [
          {
            text: 'Liste des utilisateurs professionals',
            active: true,
          },
        ],
      }
    },
    {
      path: '/apps/users/view/:id',
      name: 'apps-users-view',
      component: () => import('@/views/apps/user/users-view/UsersView.vue'),
      meta: {
        pageTitle: 'Utilisateurs',
        breadcrumb: [
          {
            text: 'Liste des utilisateurs',
            active: true,
          },
        ],
      }
    },
    {
      path: '/apps/users/edit/:id',
      name: 'apps-users-edit',
      component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
      meta: {
        pageTitle: 'Utilisateurs',
        breadcrumb: [
          {
            text: 'Liste des utilisateurs',
          },
          {
            text: 'Modifier utilisateur',
            active: true,
          },
        ],
      }
    },

    {
      path: '/others/contact',
      name: 'others-contact',
      component: () => import('@/views/apps/other/contact.vue'),
      meta: {
        pageTitle: 'Others',
        breadcrumb: [
          {
            text: 'Liste des Contactes',
          },
        ],
      },
    },

    {
      path: '/others/subscription',
      name: 'others-subscription',
      component: () => import('@/views/apps/other/subscription.vue'),
      meta: {
        pageTitle: 'Others',
        breadcrumb: [
          {
            text: 'Liste des abonnés',
          },
        ],
      },
    },
    {
      path: '/boost-requests/products',
      name: 'boost-request-products',
      component: () => import('@/views/apps/boost-requests/boost-produit.vue'),
      meta: {
        pageTitle: 'Boost Requests',
        breadcrumb: [
          {
            text: 'Products',
          },
        ],
      },
    },
    {
      path: '/boost-requests/users',
      name: 'boost-request-users',
      component: () => import('@/views/apps/boost-requests/boost-user.vue'),
      meta: {
        pageTitle: 'Boost Requests',
        breadcrumb: [
          {
            text: 'Users',
          },
        ],
      },
    },
    {
      path: '/boost-requests/banners',
      name: 'boost-request-banners',
      component: () => import('@/views/apps/boost-requests/banner-boost.vue'),
      meta: {
        pageTitle: 'Boost Requests',
        breadcrumb: [
          {
            text: 'Banners',
          },
        ],
      },
    },
      {
        path: '/add-boost/banners',
        name: 'add-boost-banners',
      component: () => import('@/views/apps/boost-requests/add-banner-boost.vue'),
      meta: {
        pageTitle: 'Add boost banner',
        breadcrumb: [
          {
            text: 'Banners',
          },
        ],
      },
    },
    {

      path: '/boost-requests/posts',
      name: 'boost-request-posts',
      component: () => import('@/views/apps/boost-requests/post-boost.vue'),
      meta: {
        pageTitle: 'Boost Requests',
        breadcrumb: [
          {
            text: 'Posts',
          },
        ],
      },
    },
  
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// router.beforeEach((to, _, next) => {
//   if (localStorage.getItem('user')) {
//     console.log("test1");
//     return next()
//   } else {
//     return next({ name: 'login' })
//   }
// })

export default router
