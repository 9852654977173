import { GetAllUserBoosts, AddUserBoostService, UpdateUserBoostService, DeleteUserBoostService  } from '@/api/boostuser.service'

export default {

    state: {
        userBoosts : []
    },

    getters: {
        getCurrentUserBoosts(state) {
            return state.userBoosts
        },
    },

    mutations: {

        setCurrentUserBoosts(state, userBoosts) {
            state.userBoosts = userBoosts
        },
        setCurrentAddUserBoosts(state, userBoost) {
            state.userBoosts.push(userBoost)
        },
        setCurrentUpdateUserBoosts(state, userBoost) {
            let newt = state.userBoosts
            for (var i = 0; i < newt.length; i++) {
                if (newt[i]._id == userBoost._id) {
                    newt[i] = userBoost
                    state.userBoosts = []
                    state.userBoosts = newt 
                }
            }
        },
        setCurrentDeleteUserBoosts(state, userBoostId) {
            for (var i = 0; i<state.userBoosts.length; i++) {
                if (state.userBoosts[i]._id == userBoostId) {
                    state.userBoosts.splice(i, 1);
                    break
                }
            }
        },

    },

    actions: {
        async ListUserBoosts(context) {
            let new_contacts = await GetAllUserBoosts()
            if(new_contacts!=="echeck")
                context.commit('setCurrentUserBoosts', new_contacts.data)

            return new_contacts
        },
        async AddUserBoost(context, item) {
            let response = await AddUserBoostService(item)
            if(response.data.data._id!==undefined) {
                
                context.commit('setCurrentAddUserBoost', response.data.data)
            }
            return response
        },
        async UpdateUserBoost(context, item) {
            let response = await UpdateUserBoostService(item)
            if (response._id !== undefined) {
                context.commit('setCurrentUpdateUserBoost', response)
                return response
            } else {
                return response
            }
        },
        async DeleteUserBoost(context, item) {
            let response = await DeleteUserBoostService(item)
            if(response.data!=='') 
                context.commit('setCurrentDeleteUserBoost', item._id)
            return response
        },

    }
}