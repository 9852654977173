import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import AuthStore from './auth.store.js'
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import PostStore from './post.store.js'
import ContactStore from './contact.store'
import SubscriptionStore from './subscription.store'
import boostUserStore from './boostUser.store'
import boostProductStore from './boostProduct.store'
import CategoryStore from './category.store'
import SubCategoryStore from './subcategory.store'
import TagsStore from './tags.store'
import PostBoostStore from './bannerBoost.store'
import BannerBoostStore from './postBoost.store'
import CommandeStore from './commande.store'
import usersStore from './users.store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    AuthStore,
    PostStore,
    ContactStore,
    CategoryStore,
    SubCategoryStore,
    TagsStore,
    SubscriptionStore,
    boostUserStore,
    boostProductStore,
    PostBoostStore,
    BannerBoostStore,
    CommandeStore,
    usersStore,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
