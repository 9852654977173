import axios from 'axios'
import config from '../config/dev.json'
import production from '../config/production.json'

const path = '/api/contact'
let url

if (process.env.NODE_ENV === 'production') url = production.host + path
else url = config.host + path

url = 'http://102.219.178.151/backend/api/userBoost'

export function GetAllUserBoosts() {
  return new Promise((resolve, reject) => {
    axios.get(url).then(response => {
      resolve(response.data)
    }).catch(err => resolve("echeck"))
  })
}

export function AddUserBoostService(userBoost) {
    return new Promise((resolve, reject) => {
        axios.post(url, userBoost).then(response => {
            resolve(response)
        }).catch(err => resolve("echeck"))
    }) 
}

export function UpdateUserBoostService(userBoost) {
    return new Promise((resolve, reject) => {
        axios.put(url + '/' +userBoost._id, userBoost).then(response => {
            resolve(response.data)
        }).catch(err => reject(err))
    }) 
}

export function DeleteUserBoostService(userBoost) {
    return new Promise((resolve, reject) => {
        axios.delete(url + '/'+ userBoost._id).then(response => {
            resolve(response)
        }).catch(err => reject(err))
    }) 
}


