import { GetAllCategories, AddCategoryService, UpdateCategoryService, DeleteCategoryService  } from '@/api/category.service'

export default {

    state: {
        categories : []
    },

    getters: {
        getCurrentCategories(state) {
            return state.categories
        },
    },

    mutations: {

        setCurrentCategories(state, categories) {
            state.categories = categories
        },
        setCurrentAddCategory(state, category) {
            state.categories.push(category)
        },
        setCurrentUpdateCategory(state, category) {
            let newt = state.categories
            for (var i = 0; i < newt.length; i++) {
                if (newt[i]._id == category._id) {
                    newt[i] = category
                    state.categories = []
                    state.categories = newt 
                }
            }
        },
        setCurrentDeleteCategory(state, categoryId) {
            for (var i = 0; i<state.categories.length; i++) {
                if (state.categories[i]._id == categoryId) {
                    state.categories.splice(i, 1);
                    break
                }
            }
        },

    },

    actions: {
        async ListCategories(context) {
            let new_contacts = await GetAllCategories()
            if(new_contacts!=="echeck")
                context.commit('setCurrentCategories', new_contacts.data)

            return new_contacts
        },
        async AddCategory(context, item) {
            let response = await AddCategoryService(item)
            if(response.data.data._id!==undefined) {
                
                context.commit('setCurrentAddCategory', response.data.data)
            }
            return response
        },
        async UpdateCategory(context, item) {
            let response = await UpdateCategoryService(item)
            if (response._id !== undefined) {
                context.commit('setCurrentUpdateCategory', response)
                return response
            } else {
                return response
            }
        },
        async DeleteCategory(context, item) {
            let response = await DeleteCategoryService(item)
            if(response.data!=='') 
                context.commit('setCurrentDeleteCategory', item._id)
            return response
        },

    }
}