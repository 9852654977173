import axios from 'axios'
import config from '../config/dev.json'
import production from '../config/production.json'

const path = '/api/contact'
let url

if (process.env.NODE_ENV === 'production') url = production.host + path
else url = config.host + path

url = 'http://102.219.178.151/backend/api/productBoost'

export function GetAllProductBoosts() {
  return new Promise((resolve, reject) => {
    axios.get(url).then(response => {
      resolve(response.data)
    }).catch(err => resolve("echeck"))
  })
}

export function AddProductBoostService(postBoost) {
    return new Promise((resolve, reject) => {
        axios.post(url, postBoost).then(response => {
            resolve(response)
        }).catch(err => resolve("echeck"))
    }) 
}

export function UpdateProductBoostService(postBoost) {
    return new Promise((resolve, reject) => {
        axios.put(url + '/' +postBoost._id, postBoost).then(response => {
            resolve(response.data)
        }).catch(err => reject(err))
    }) 
}

export function DeleteProductBoostService(postBoost) {
    return new Promise((resolve, reject) => {
        axios.delete(url + '/'+ postBoost._id).then(response => {
            resolve(response)
        }).catch(err => reject(err))
    }) 
}


