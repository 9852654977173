import { ListPostsService  } from '@/api/post.service.js'

export default {

    state: {
        postss: [],
        productss: []
    },

    getters: {
        getCurrentProductss(state) {
            return state.productss
        },
    },

    mutations: {

        setCurrentPostss(state, postss) {
            state.postss = postss
        },
        setCurrentProductss(state, productss) {
            state.productss = productss
        },

        setCurrentDeleteProduct(state, productId) {
            for (var i = 0; i<state.productss.length; i++) {
                if (state.productss[i].id == productId) {
                    state.productss.splice(i, 1);
                    break
                }
            }
        },

        setCurrentAddProduct(state, product) {
            state.productss.push(product)
        },

        setCurrentUpdateProduct(state, product) {
            for (var i = 0; i < state.productss.length; i++) {
                if (state.productss[i].id == product.id) {
                    state.productss[i] = product
                    return
                }
            }

        },
    },

    actions: {
        async ListPostss(context) {
            let new_postss = await ListPostssService()
            let new_productss = []
            for (let i = 0; i < new_postss.length; i++) {
                new_productss.push(new_postss[i].product)
            }
           // context.commit('setCurrentPostss', new_postss)
            //context.commit('setCurrentProductss', new_productss)

            return new_postss
        },

       /* async DeleteProduct(context, item) {
            let response = await DeleteProductSservice(item)
            if(response.response==="Product succsesfully delete!")
                context.commit('setCurrentDeleteProduct', item.id)
            return response
        },

        async AddProduct(context, item) {
            let response = await AddProductSservice(item)
            if (response.response == "product added!") {
                let categories = await ListCategoriesService();
                for (let i = 0; i < categories.length; i++) {
                    if (categories[i].id == response.data.category) {
                        response.data.category_title = categories[i].title
                        break;
                    }
                }
                context.commit('setCurrentAddProduct', response.data)
            }
            console.log("product "+JSON.stringify(response))
            return response
        },

        async UpdateProduct(context, item) {
            let response = await UpdateProductSservice(item)
            if (response.response == "product updated!") {
                let categories = await ListCategoriesService();
                for (let i = 0; i < categories.length; i++) {
                    if (categories[i].id == response.data.category) {
                        response.data.category_title = categories[i].title
                        break;
                    }
                }
                context.commit('setCurrentUpdateProduct', response.data)
            }
            return response
        },*/

    }
}