import axios from 'axios'
import config from '../config/dev.json'
import production from '../config/production.json'

let path = '/api'
let url;

if (process.env.NODE_ENV === 'production')
    url = production.host + path;
else
    url = config.host + path;

url = 'http://102.219.178.151/backend/api/'

export function ListPostsService() {
    return new Promise((resolve, reject) => {
        axios.get(url + 'api/posts').then(response => {
            resolve(response.data.data)
        }).catch(err => reject(err))
    }) 
}

/*export function DeleteCategoryService(category) {
    return new Promise((resolve, reject) => {
        axios.delete(url + '/category-delete/'+category.id+'/').then(response => {
            resolve(response.data)
        }).catch(err => reject(err))
    }) 
}

export function AddCategoryService(category) {
    return new Promise((resolve, reject) => {
        axios.post(url + '/category-create/', category).then(response => {
            resolve(response.data)
        }).catch(err => reject(err))
    }) 
}

export function UpdateCategoryService(category) {
    return new Promise((resolve, reject) => {
        axios.post(url + '/category-update/'+category.id+'/', category).then(response => {
            resolve(response.data)
        }).catch(err => reject(err))
    }) 
}

export function GetCategoryByIdService(id) {
    return new Promise((resolve, reject) => {
        axios.get(url + '/category-detail/'+id+'/').then(response => {
            resolve(response.data)
        }).catch(err => reject(err))
    }) 
}*/