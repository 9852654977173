import { GetAllSubscription  } from '@/api/subscription.service.js'

export default {

    state: {
        subscriptions : []
    },

    getters: {
        getCurrentSubscriptions(state) {
            return state.subscriptions
        },
    },

    mutations: {

        setCurrentSubscriptions(state, subscriptions) {
            state.subscriptions = subscriptions
        },
       
    },

    actions: {
        async ListSubscriptions(context) {
            let new_subscriptions = await GetAllSubscription()
           
            if(new_subscriptions!=="echeck")
                context.commit('setCurrentSubscriptions', new_subscriptions.data)

            return new_subscriptions
        },
    }
}