import { GetAllBannerBoosts, AddBannerBoostService, UpdateBannerBoostService, DeleteBannerBoostService  } from '@/api/bannerboost.service'

export default {

    state: {
        banners : []
    },

    getters: {
        getCurrentBannerBoosts(state) {
            return state.banners
        },
    },

    mutations: {

        setCurrentBannerBoosts(state, banners) {
            state.banners = banners
        },
        setCurrentAddBannerBoosts(state, banner) {
            state.banners.push(banner)
        },
        setCurrentUpdateBannerBoosts(state, banner) {
            let newt = state.banners
            for (var i = 0; i < newt.length; i++) {
                if (newt[i]._id == banner._id) {
                    newt[i] = banner
                    state.banners = []
                    state.banners = newt 
                }
            }
        },
        setCurrentDeleteBannerBoosts(state, bannerId) {
            for (var i = 0; i<state.banners.length; i++) {
                if (state.banners[i]._id == bannerId) {
                    state.banners.splice(i, 1);
                    break
                }
            }
        },

    },

    actions: {
        async ListBannerBoosts(context) {
            let new_contacts = await GetAllBannerBoosts()
            if(new_contacts!=="echeck")
                context.commit('setCurrentBannerBoosts', new_contacts.data)

            return new_contacts
        },
        async AddBannerBoost(context, item) {
            let response = await AddBannerBoostService(item)
            if(response.data.data._id!==undefined) {
                
                context.commit('setCurrentAddBannerBoost', response.data.data)
            }
            return response
        },
        async UpdateBannerBoost(context, item) {
            let response = await UpdateBannerBoostService(item)
            if (response._id !== undefined) {
                context.commit('setCurrentUpdateBannerBoost', response)
                return response
            } else {
                return response
            }
        },
        async DeleteBannerBoost(context, item) {
            let response = await DeleteBannerBoostService(item)
            if(response.data!=='') 
                context.commit('setCurrentDeleteBannerBoost', item._id)
            return response
        },

    }
}