import { GetAllContact, DeleteContactService  } from '@/api/contact.service.js'

export default {

    state: {
        contacts : []
    },

    getters: {
        getCurrentContacts(state) {
            return state.contacts
        },
    },

    mutations: {

        setCurrentContacts(state, contacts) {
            state.contacts = contacts
        },

        setCurrentDeleteContact(state, contactId) {
            for (var i = 0; i<state.contacts.length; i++) {
                if (state.contacts[i]._id == contactId) {
                    state.contacts.splice(i, 1);
                    break
                }
            }
        },

    },

    actions: {
        async ListContacts(context) {
            let new_contacts = await GetAllContact()
            if(new_contacts!=="echeck")
                context.commit('setCurrentContacts', new_contacts.data)

            return new_contacts
        },

        async DeleteContact(context, item) {
            let response = await DeleteContactService(item)
            if(response.data!=='') 
                context.commit('setCurrentDeleteContact', item._id)
            return response
        },
    }
}