import { GetAllCommandes, AddCommandeService, UpdateCommandeService, DeleteCommandeService  } from '@/api/commande.service'

export default {

    state: {
        commandes : []
    },

    getters: {
        getCurrentCommandes(state) {
            return state.commandes
        },
    },

    mutations: {

        setCurrentCommandes(state, commandes) {
            state.commandes = commandes
        },
        setCurrentAddCommande(state, commande) {
            state.commandes.push(commande)
        },
        setCurrentUpdateCommande(state, commande) {
            let newt = state.commandes
            for (var i = 0; i < newt.length; i++) {
                if (newt[i]._id == commande._id) {
                    newt[i] = commande
                    state.commandes = []
                    state.commandes = newt 
                }
            }
        },
        setCurrentDeleteCommande(state, commandeId) {
            for (var i = 0; i<state.commandes.length; i++) {
                if (state.commandes[i]._id == commandeId) {
                    state.commandes.splice(i, 1);
                    break
                }
            }
        },

    },

    actions: {
        async ListCommandes(context) {
            let new_contacts = await GetAllCommandes()
            if(new_contacts!=="echeck")
                context.commit('setCurrentCommandes', new_contacts.data)

            return new_contacts
        },
        async AddCommande(context, item) {
            let response = await AddCommandeService(item)
            if(response.data.data._id!==undefined) {
                
                context.commit('setCurrentAddCommande', response.data.data)
            }
            return response
        },
        async UpdateCommande(context, item) {
            let response = await UpdateCommandeService(item)
            if (response._id !== undefined) {
                context.commit('setCurrentUpdateCommande', response)
                return response
            } else {
                return response
            }
        },
        async DeleteCommande(context, item) {
            let response = await DeleteCommandeService(item)
            if(response.data!=='') 
                context.commit('setCurrentDeleteCommande', item._id)
            return response
        },

    }
}