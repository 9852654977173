import { GetAllCategories, AddTagsService, UpdateTagsService, DeleteTagsService  } from '@/api/tags.service'

export default {

    state: {
        tags : []
    },

    getters: {
        getCurrentTags(state) {
            return state.tags
        },
    },

    mutations: {

        setCurrentTags(state, tags) {
            state.tags = tags
        },
        setCurrentAddSTags(state, tags) {
            state.tags.push(tags)
        },
        /*setCurrentUpdateCategory(state, category) {
            let newt = state.categories
            for (var i = 0; i < newt.length; i++) {
                if (newt[i]._id == category._id) {
                    newt[i] = category
                    state.categories = []
                    state.categories = newt 
                }
            }
        },
        setCurrentDeleteCategory(state, categoryId) {
            for (var i = 0; i<state.categories.length; i++) {
                if (state.categories[i]._id == categoryId) {
                    state.categories.splice(i, 1);
                    break
                }
            }
        },*/

    },

    actions: {
        async ListTags(context) {
            let new_categories = await GetAllCategories()
            console.log("new_categories",new_categories);
            let tags = []
            if (new_categories !== "echeck") {
                for (let i = 0; i < new_categories.data.length; i++) {
                    console.log("haha ",new_categories.data[i])
                    for (let j = 0; j < new_categories.data[i].subCategories.length; j++) {
                        for (let j = 0; j < new_categories.data[i].subCategories[j].tags.length; j++) {
                            new_tags.push(new_categories.data[i].subCategories[j].tags[j])
                        }

                    }
                }
                context.commit('setCurrentTags', tags)
            }

            return new_tags
        },
        async AddTags(context, item) {
            let response = await AddTagsService(item)
            if(response.data._id!==undefined) {
            let new_categories = await GetAllCategories()
                let new_tags = []
            if (new_categories !== "echeck") {
                for (let i = 0; i < new_categories.data.length; i++) {
                    console.log("haha ",new_categories.data[i])
                    for (let j = 0; j < new_categories.data[i].subCategories.length; j++) {
                        for (let j = 0; j < new_categories.data[i].subCategories[j].tags.length; j++) {
                            new_tags.push(new_categories.data[i].subCategories[j].tags[j])
                        }

                    }
                }
                context.commit('setCurrentTags', new_tags)
            }
                return new_tags
            }
        },
        async UpdateTags(context, item) {
            let response = await UpdateTagsService(item)
            console.log("after update ",response)
            let new_categories = await GetAllCategories()
            let new_tags = []
            if (new_categories !== "echeck") {
                for (let i = 0; i < new_categories.data.length; i++) {
                    for (let j = 0; j < new_categories.data[i].subCategories.length; j++) {
                        for (let j = 0; j < new_categories.data[i].subCategories[j].tags.length; j++) {
                            new_tags.push(new_categories.data[i].subCategories[j].tags[j])
                        }

                    }
                }
                context.commit('setCurrentTags', new_tags)
            }
            return new_tags
           
        },
        async DeleteTags(context, item) {
            let response = await DeleteTagsService(item)
            let new_categories = await GetAllCategories()
            let new_Tags = []
            if (new_categories !== "echeck") {
                for (let i = 0; i < new_categories.data.length; i++) {
                    for (let j = 0; j < new_categories.data[i].subCategories.length; j++) {
                        for (let j = 0; j < new_categories.data[i].subCategories[j].tags.length; j++) {
                            new_tags.push(new_categories.data[i].subCategories[j].tags[j])
                        }

                    }
                }
                context.commit('setCurrentTags', new_tags)
            }
            return new_tags
        },

    }
}